import styled from '@emotion/styled';
import {faInstagram, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { ButtonCircularIcon } from './buttons/ButtonCircularIcon';

const Navbar = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    position: fixed;
    width: 100%;
    top: 1rem;
    left: -1rem;
    z-index: 10;

    @media (max-width: 800px) {
        justify-content: center;
    }
`

function isMobileSize() {
    return window.screen.availWidth < 800;
}

export const NavigationBar = () => {
    return (
        <Navbar>
            <ButtonCircularIcon action={() => { window.open('mailto:danielamorales.psi@gmail.com') }} icon={faEnvelope} title='Email' />
            <ButtonCircularIcon action={() => { window.open('https://linkedin.com/in/damormes', '_blank') }} icon={faLinkedin} title='Linkedin' />
            <ButtonCircularIcon action={() => { window.open('https://instagram.com/dmpsicologia', '_blank') }} icon={faInstagram} title='Instagram' />
            {isMobileSize() && <ButtonCircularIcon action={() => { window.open('tel:+34618461007') }} icon={faPhone} title='Teléfono de contacto' />}
        </Navbar>
    );
}