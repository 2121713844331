import styled from "@emotion/styled";
import { MouseEventHandler } from "react";

interface Props {
    action: MouseEventHandler;
    text: string;
}

export const ButtonPrimary = ({ action, text }: Props) => {
    const Button = styled.button`
        min-width: 15rem;
        background-color: var(--primary-color-alpha);
        color: black;
        font-size: 20px;
        font-weight: bold;
        border-radius: 24px;
        padding: 1rem;
        cursor: pointer;
        border: none;
    `

    return (
        <Button onClick={action}>
            {text.toUpperCase()}
        </Button>
    );
}