import styled from '@emotion/styled';

const AboutMeSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 100vh;
    
    h1 {
        margin: 4rem 0 0 8rem;
        font-size: var(--subtitle);
    }

    @media (max-width: 800px) {
        h1 {
            font-size: var(--title-sm);
            text-align: center;
            margin: 4rem 0;
        }
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    margin: 3rem;
    gap: 6rem;

    img {
        border-radius: 0 120px 0 0;
        width: 35%;
    }

    @media (max-width: 800px) {
        flex-direction: column;
        gap: 3rem;

        img {
            width: 80%;
        }
    }

    @media (max-width: 650px) {

        img {
            border-radius: 0 60px 0 0;
            width: 90%;
        }
    }
`

const Info = styled.div`
    width: 50%;
    height: fit-content;
    align-self: center;
    font-size: var(--text-xl);

    @media (max-width: 800px) {
        width: 100%;
        font-size: var(--text-md);
    }
`

export const AboutMe = () => {
    return (
        <AboutMeSection id='AboutMe'>
            <h1>Conóceme</h1>
            <Content>
                <img src='/perfil.webp' alt='foto perfil'></img>
                <Info>
                    <p>Psicóloga <strong>Graduada en Psicología</strong> por la Universidad de La Laguna 2017; con Máster en <strong>Psicología General Sanitaria</strong> por la Universidad Europea de Canarias en 2022.</p>
                    <p>Colegiada <strong>T-03325</strong></p>
                    <p>Miembra de la División de Psicología del Trabajo, de las Organizaciones y los Recursos Humanos (<strong>PTORH</strong>) desde el 2020.</p>
                    <p>Habilitada como <strong>Experta en Psicología de Emergencias y Catástrofes</strong> en 2020 por el Consejo General de Colegios Oficiales de Psicólogos de España.</p>
                    <p>Habilitada en <strong>EMDR</strong> Nivel I en 2022 por el Instituto Español de EMDR.</p>
                </Info>
            </Content>
        </AboutMeSection>
    );
}