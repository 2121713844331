import styled from '@emotion/styled';
import { NavigationBar } from './components/NavigationBar';
import { AboutMe } from './sections/About_me';
import { Appointments } from './sections/Appointments';
import { Footer } from './components/footer';
import { Home } from './sections/Home';
import { Services } from './sections/Servicios';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
`

function App() {
  return (
    <Container>
      <NavigationBar />
      <Home />
      <AboutMe />
      <Services />
      <Appointments />
      <Footer />
    </Container>
  );
}

export default App;
