import styled from "@emotion/styled/macro";

interface Props {
    title: string;
    description: string;
    image: string;
}


export const CardFlip = ({ title, description, image }: Props) => {

    const FrontCard = styled.div`
        width: 100%;
        height: 100%;
        border-radius: 18px;
        background-color: white;
        transition: .5s ease;
    `

    const BackCard = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: 80%;
        height: 100%;
        border-radius: 18px 0 0  18px;
        background: white;
        transition: .5s;
        transform-origin: left;
        transform: perspective(2000px) rotateY(-90deg);
        display: flex;
        justify-content: center;
        align-items: center;
        
        p {
            max-height: 90%;
            margin: 1rem;
            overflow: hidden;
            font-size: 14px;
            font-weight: 400;
        }
    `

    const Card = styled.div`
        width: 17rem;
        border-radius: 18px;
        height: 350px;
    `

    const Content = styled.div`
        font-weight: bold;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        
        :hover ${FrontCard} {
            opacity: .5;
            transform: translateX(30%);
        }

        :hover ${BackCard} {
            transform: perspective(2000px) rotateY(0deg);
        }
    `

    const Image = styled.div`
        height: 85%;
        background-image: url(${image});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 18px 18px 0 0;
    `

    const Title = styled.div`
        overflow: hidden;
        text-align: center;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15%;
    `

    return (
        <Card>
            <Content>
                <FrontCard>
                    <Image />
                    <Title>{title.toLocaleUpperCase()}</Title>
                </FrontCard>
                <BackCard>
                    <p>{description}</p>
                </BackCard>
            </Content>
        </Card>
    );
}