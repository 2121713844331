import styled from '@emotion/styled';
import { CardFlip } from '../components/cards/CardFlip';
import CardContent from '../components/cards/content';

const ServicesSection = styled.section`
    display: flex;
    justify-content: center;
    min-height: 100vh;
`

const Content = styled.section`
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-color-alpha);
    width: 70%;
    justify-content: space-evenly;

    h1 {
        font-size: var(--subtitle);
        text-align: center;
        margin: 1rem;
    }

    @media (max-width: 1000px) {
        width: 100%;
    }

    @media (max-width: 800px) {
        h1 {
            font-size: var(--title-sm);
        }
    }
`

const CardList = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--secondary-color-alpha);
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding: 3rem;
`

export const Services = () => {
    return (
        <ServicesSection id='Services'>
            <Content>
                <h1>Mis especialidades</h1>
                <CardList>
                    <CardFlip title='Terapia Individual' description={CardContent.individual_therapy} image='individual_therapy.webp' />
                    <CardFlip title='Terapia de Pareja' description={CardContent.couple_therapy} image='couple_therapy.webp' />
                    <CardFlip title='Terapia de Familia' description={CardContent.family_therapy} image='family_therapy.webp' />
                    <CardFlip title='Terapia Infanto-Juvenil' description={CardContent.child_juvenile_therapy} image='child_therapy.webp' />
                    <CardFlip title='Terapia de Grupo' description={CardContent.group_therapy} image='group_therapy.webp' />
                    <CardFlip title='Terapia Sexológica' description={CardContent.sexological_therapy} image='sexological_therapy.webp' />
                    <CardFlip title='EMDR' description={CardContent.emdr} image='emdr.webp' />
                    <CardFlip title='Terapias Sugestivas' description={CardContent.suggestive_therapy} image='suggestive_therapy.webp' />
                    <CardFlip title='Servicios a Empresa' description={CardContent.company_service} image='company_services.webp' />
                </CardList>
            </Content>
        </ServicesSection>
    );
}