import styled from '@emotion/styled';

const AppointmentsSection = styled.section`
    display: flex;
    justify-content: center;
    min-height: 100vh;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 80%;
  padding: 3rem 0;

  @media (max-width: 800px) {
    flex-direction: column;
    width: 90%;
    margin: 3rem 0;
  }
`

const AppointmentTypeInfo = styled.section`
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    gap: 1rem;

    h1, h4 {
        margin: 0;
    }

    h1 {
        font-size: var(--title-sm);
    } 
    
    img {
        max-width: 90%;
        max-height: 350px;
        margin: 3rem 0;
    }
    
    iframe {
        width: 100%;
        height: 40vh;
    }
  
    ul {
        margin: 0;
    }

    li {
        margin-top: 1rem;
    }

    @media (max-width: 800px) {
        width: 100%;
    }
`

const Separator = styled.div`
    width: 4px;
    height: 80%;
    background-color: black;
`

export const Appointments = () => {
    return (
        <AppointmentsSection id='Appointments'>
            <Content>
                <AppointmentZoom />
                <Separator />
                <AppointmentFaceToFace />
            </Content>
        </AppointmentsSection>
    );
}

const AppointmentZoom = () => {
    return (
        <AppointmentTypeInfo>
            <h1>Citas online</h1>
            <p>En esta modalidad de atención, se le hará llegar al usuario unos formularios que habrá de cumplimentar y enviar para la aceptación de las sesiones por videoconferencia y la protección de datos. </p>
            <p>Es preciso para esta modalidad que el usuario precise de cámara y micrófono operativo en el momento de la consulta. </p>
            <img src='/video-conference.webp' alt='logo' />
        </AppointmentTypeInfo>
    );
}

const AppointmentFaceToFace = () => {
    return (
        <AppointmentTypeInfo>
            <h1>Citas Presenciales</h1>
            <h4>Isla de Tenerife</h4>
            <p>Lugares de atención presencial:</p>
            <ul>
              <li><strong><u>San Cristóbal de La Laguna:</u></strong>  Av. de los Menceyes, 232, 38320 La Laguna, Santa Cruz de Tenerife.</li>
              <li><strong><u>Integrity:</u></strong>  C. de Arriba, 34, 38680 Guía de Isora, Santa Cruz de Tenerife.</li>
            </ul>
            <iframe src="https://www.google.com/maps/d/embed?mid=16j1uHz_t2UR02tmoY7pWVVq7bhSb4Ac&hl=es&ehbc=2E312F" title='Lugares de atención presencial'></iframe>
        </AppointmentTypeInfo>
    );
}