import styled from '@emotion/styled';
import {ButtonPrimary} from '../components/buttons/ButtonPrimary';
import {ButtonSecondary} from '../components/buttons/ButtonSecondary';

const HomeSection = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-image: url('/home_image_1.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  justify-content: center;

  @media (max-width: 800px) {
    align-items: center;
  }
`

const Content = styled.article`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 3rem;
  gap: 4rem;

  img {
    height: 20rem;
  }
  
  @media (max-width: 800px) {
    flex-direction: column;
    
    img {
      height: 16rem;
    }
  }
`

const Title = styled.h1`
  font-size: var(--title);
  line-height: 6rem;
  text-align: center;
  margin: 0;

  @media (max-width: 800px) {
    font-size: var(--subtitle);
    line-height: 4rem;
  }
`

const SubTitle = styled.h1`
  font-size: var(--subtitle);
  font-weight: 300;
  text-align: center;
  margin: 0;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  margin-top: 4rem;

  @media (max-width: 800px) {
    gap: 1rem;
    margin-top: 2rem;
    flex-direction: column;
  }
`

const Actions = styled.section`
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const Home = () => {
  return (
    <HomeSection id='Home'>
      <Content>
        <img src='/logo_white_big.png' alt='Logo' />
        <Actions>
          <Title>Daniela Morales</Title>
          <SubTitle>Psicóloga</SubTitle>
          <Buttons>
            <ButtonPrimary text='cita online' action={() => window.open('https://wa.me/34618461007?text=Hola Daniela, me gustaría agendar una cita ONLINE contigo')}/>
            <ButtonSecondary text='cita precensial' action={() => window.open('https://wa.me/34618461007?text=Hola Daniela, me gustaría agendar una cita PRECENCIAL contigo')}/>
          </Buttons>
        </Actions>
      </Content>
    </HomeSection>
  );
}