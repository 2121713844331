export default class CardContent {
    static individual_therapy = 'Trabajaremos en los aspectos de tu vida que te causen malestar. Seré tu guía en el camino ofreciéndote herramientas y estrategias para la adaptación, aceptación y superación de los síntomas por los que estés pasando en la actualidad.'
    static couple_therapy = 'No consiguen resolver determinados conflictos, lidiar con situaciones que les afectan, mejorar el vínculo que hay en la pareja, la psicoterapia de pareja puede resolver estos aspectos.\nCuando la mejor decisión es la separación, la terapia nos serviría para preparar el proceso de ruptura y gestionar el duelo por la misma.'
    static family_therapy = 'La terapia de familia debe tener una base sistémica, es decir, se interviene en cómo interactúa y se influyen las diferentes personas de las que se compone esa unidad familiar. Cambiando los patrones de comportamiento y comunicación para encontrar el equilibrio.'
    static child_juvenile_therapy = 'Abarca el bienestar emocional y social de los menores, interviniendo principalmente en las emociones y conductas de los mismos.'
    static group_therapy = 'Grupos pequeños (3-10) de los que formarán parte personas que tengan un perfil similar, en cuanto a padecimiento psicológico se refiere. Las sesiones de grupo ayudan al apoyo social, la expresión vivencial y el autoconocimiento.'
    static sexological_therapy = 'Según la OMS “La sexualidad es un aspecto central del ser humano que está presente a lo largo de su vida. Abarca el sexo, las identidades y los roles de género, la orientación sexual, el erotismo, el placer, la intimidad y la reproducción". La terapia sexológica está centrada en las dificultades o trastornos de la vida sexual, abarcando no solo los problemas físicos, sino también los emocionales.'
    static emdr = 'Tratamiento de Desensibilización y Reprocesamiento por Movimientos Oculares (Eyes Movement Desensitization and Reprocessing), consiste en transformar las experiencias traumáticas (recuerdos almacenados disfuncionalmente) a una forma de resolución más adaptativa.'
    static suggestive_therapy = 'El profesional de la psicología practica la hipnosis clínica para experimentar cambios en las percepciones, las sensaciones y los pensamientos. Habitualmente se aplica para el manejo del dolor, el trauma, trastornos psicofisiológicos o gestión de la ansiedad, también, para buscar la causa principal de algún problema.'
    static company_service = 'Selección de Personal.\nFormación a Empresas.\nAtención psicológica al empleado.'
}