import styled from '@emotion/styled';

const FooterSection = styled.section`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 10%;
    width: 100%;
    background-color: var(--footer-color);
    padding: 1rem 0;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`

const ContactInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;

    @media (max-width: 800px) {
        min-height: 100%;
        width: 100%;
        padding: 0;
    }
`

const Section = styled.div`
    width: 50%;
    margin: .5rem;
    text-align: left;

    h4 {
        margin: 0;
    }
`

export const Footer = () => {
    return (
        <FooterSection id='Footer'>
            <ContactInfo>
                <Section>
                    <h4>CORREO ELECTRÓNICO</h4>
                    <a href='mailto:danielamorales.psi@gmail.com'>danielamorales.psi@gmail.com</a>
                </Section>
                <Section>
                    <h4>TELÉFONO</h4>
                    <a href='tel:+34618461007'>+34 618461007</a>
                </Section>
                <Section>
                    <h4><a href='/AVISO_LEGAL.pdf' target="_blank">AVISO LEGAL</a> y <a href='/Protección de datos.pdf' target="_blank">PROTECCIÓN DE DATOS</a></h4>
                </Section>
            </ContactInfo>
        </FooterSection>
    );
}