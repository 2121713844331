import styled from "@emotion/styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from "react";

interface Props {
    action: MouseEventHandler;
    icon: IconProp;
    title?: string;
    iconColor?: string;
    backgroundColor?: string;
    size?: 'x-large' | 'medium' | 'xx-large'
}

export const ButtonCircularIcon = ({ action, icon, title = '', iconColor = 'white', backgroundColor = 'var(--primary-color-alpha)', size = 'x-large' }: Props) => {
    const Button = styled.button`
        background-color: ${backgroundColor};
        height: fit-content;
        border-radius: 100%;
        padding: .5rem;
        cursor: pointer;
        border: none;
        transition: 0.5s;
    
        :hover {
            background-color: transparent;
        }
    
        :hover svg {
            color: ${backgroundColor};
        }
    `

    return (
        <Button onClick={action}>
            <FontAwesomeIcon icon={icon} color={iconColor} title={title} style={{ fontSize: size }} />
        </Button>
    );
}